<template>
  <shipping-label v-for="pkgEntity in packages" :key = "pkgEntity.id" :receipt="receipts[pkgEntity.receiptCode]" :entity="pkgEntity"/>
  <div id="__loaded__" style="display:none">{{loaded}}</div>
</template>
<script>
import ShipmentService from '@/service/ShipmentService';
import ReceiptService from '@/service/ReceiptService';
import ShippingLabel from './components/ShippingLabel';
export default {
  components: {ShippingLabel},
  data() {
    return {
      receipts: {},
      packages: [{}],
      loaded:''
    }
  },
  methods: {
    onAfterPrint(event) {
      console.log(event);
    }
  },
  mounted() {
    const {resource, id, packageId} = this.$route.params;
    if(id) {
      if (resource == 'shipment') {
        ShipmentService.get(id).then(data => {
          let receipts = {};
          if(this.shipment && Array.isArray(this.shipment.receipts)) {
            for(let i = 0; i < this.shipment.receipts.length; i++) {
              receipts[this.shipment.receipts[i].receiptCode] = this.shipment.receipts[i];
            }
          }
          this.receipts = receipts;
          if (packageId != undefined && packageId != null && Array.isArray(data.packages)) {
            this.packages = data.packages.filter(item => item.id.toString() == packageId);
          } else {
            this.packages = data.packages;
          }
          this.loaded='loaded';
        })
      } else if (resource == 'receipt') {
        ReceiptService.get(id).then(data => {
          if (data) {
            this.receipts = {
              [data.receiptCode]: data
            };
            if (packageId != undefined && packageId != null && Array.isArray(data.packageEntities)) {
              this.packages = data.packageEntities.filter(item => item.id.toString() == packageId);
            } else {
              this.packages = data.packageEntities;
            }
          }          
          this.loaded='loaded';
        })
      }
    }
  }
}
</script>

<style type = "text/css">
@page {
  size: 100mm 150mm;
  margin: 1mm;
}
.shipping-label {
  width: 100mm;
  height: 150mm;
}
.footer {
  width: 100mm;
  border-bottom-style: dotted;
  border-width: thin;
}
/*
body {
  width: 210mm;
  height: 148.5mm;
  margin-left: 5mm;
  margin-right: 5mm;
  margin-top: 5mm;
}
*/
@media print {
  p.bodyText {font-family:verdana, arial, sans-serif;}
  html, body {
      width: 100mm;
      height: 150mm;
    }
  .footer {
    width: 100mm;
    border-bottom-style: dotted;
    border-width: 0px;
  }
}
</style>
